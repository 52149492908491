import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import routenames from '../constants/routenames';
import routetitles from '../constants/routetitles';

// Replace /project/:id => ^/project/[0-9]+$
const regExpRoutes = Object.values(routenames).map(
  (route) =>
    new RegExp(
      `^${route
        .split('/')
        .map((r) => (r.startsWith(':') ? '[0-9]+' : r))
        .join('/')}$`
    )
);

function searchRoute(pathname) {
  const index = regExpRoutes.findIndex((reg) => {
    return reg.test(pathname);
  });
  const names = Object.keys(routenames);
  return routenames[names[index]];
}

function listener({ pathname }) {
  document.title =
    routetitles[pathname] || routetitles[searchRoute(pathname)] || 'ABN';
}

const TitleController = () => {
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen(listener);
    listener(history.location);
    return () => {
      unlisten();
    };
  }, [history]);
  return null;
};

TitleController.propTypes = {};

export default TitleController;
