import i18next from 'i18next';
import translationEn from './locales/en/translation.json';
import translationEs from './locales/es/translation.json';
import { LANGUAGE_KEY } from './constants/local-storage-keys';

i18next.init({
  interpolation: { escapeValue: false },
  lng: window.localStorage.getItem(LANGUAGE_KEY) || 'es',
  fallbackLng: 'en',
  resources: {
    en: {
      translation: translationEn,
    },
    es: {
      translation: translationEs,
    },
  },
});

export default i18next;
