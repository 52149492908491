/* eslint-disable no-underscore-dangle */
import contentServices from '../../services/content-services';
import paginationSelectors from '../pagination/pagination-selectors';

export default (dispatch) => ({
  async get({ collection, params, transform = (item) => item }, state) {
    try {
      const parameters = params || {};
      const pagination = paginationSelectors.get(collection)(state);
      if (pagination.limit) {
        parameters._limit = pagination.limit;
        parameters._start = pagination.page * pagination.limit;
      }
      const data = await contentServices.get(collection, parameters);
      dispatch.content.set({ collection, data: transform(data) });
      return null;
    } catch (errorMessage) {
      return errorMessage;
    }
  },
  async getOne({ collection, id, transform = (item) => item }) {
    try {
      const data = await contentServices.getOne(collection, id);
      dispatch.content.setCurrent({ collection, data: transform([data])[0] });
      return null;
    } catch (errorMessage) {
      return errorMessage;
    }
  },
  async create({ collection, data, params, transform = (item) => item }) {
    try {
      const { id, ...values } = data;
      await contentServices.create(collection, values);
      await dispatch.content.get({ collection, params, transform });
      return null;
    } catch (errorMessage) {
      return errorMessage;
    }
  },
  async update({ collection, data, params, transform = (item) => item }) {
    try {
      const { id, ...values } = data;
      await contentServices.update(collection, id, values);
      await dispatch.content.get({ collection, params, transform });
      return null;
    } catch (errorMessage) {
      return errorMessage;
    }
  },
  async delete({ collection, id, params, transform = (item) => item }) {
    try {
      await contentServices.delete(collection, id);
      await dispatch.content.get({ collection, params, transform });
      return null;
    } catch (errorMessage) {
      return errorMessage;
    }
  },
});
