import i18n from '../i18n';

export default {
  es: {
    label: i18n.t('languages.es.label'),
    flag: '🇪🇸',
  },
  en: {
    label: i18n.t('languages.en.label'),
    flag: '🇬🇧',
  },
};
