import reducers from './auth-reducers';
import effects from './auth-effects';

export default {
  state: {
    user: {},
    error: null,
    permissionsInCurrentProject: null,
  },
  effects,
  reducers,
};
