import reducers from './catalog-reducers';
import effects from './catalog-effects';

export default {
  state: {
    itemsToApprove: [],
    itemsFailed: [],
    error: null,
  },
  effects,
  reducers,
};
