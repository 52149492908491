import StrapiAxios from './StrapiAxios';

export default {
  async create({ username, email, password }) {
    return StrapiAxios.post('/auth/local/register', {
      username,
      email,
      password,
    });
  },
};
