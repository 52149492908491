import ROLES from '../../constants/roles-constants';

const REDUCERS = {
  getError(rootState) {
    return rootState.auth.error;
  },
  getUserInfo(rootState) {
    return rootState.auth.user;
  },
  getUserRole(rootState) {
    return rootState.auth.user?.role?.id;
  },
  getLoading(rootState) {
    return rootState.loading.models.auth;
  },
  getPermissionsInCurrentProject(rootState) {
    const role = REDUCERS.getUserRole(rootState);
    if (role === ROLES.ADMIN) {
      return 'write';
    }
    return rootState.auth.permissionsInCurrentProject || 'read';
  },
};

export default REDUCERS;
