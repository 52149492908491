import ConverterAxios from './ConverterAxios';
import StrapiAxios from './StrapiAxios';
import itemTransformations from '../state/content/transforms/item-transformations';

function getKey(name = {}) {
  return `${name.es}-${name.en}`;
}

function parseFails(fails = []) {
  const parsed = {};
  fails.forEach((fail) => {
    const key = getKey(fail.name);
    const reasons = fail.reason.split(';');
    if (!parsed[key]) {
      parsed[key] = {
        name: fail.name,
        reason: reasons,
      };
    } else {
      parsed[key].reason = [...parsed[key].reason, ...reasons];
    }
  });
  const itemsParsed = Object.values(parsed);
  return itemsParsed.map((item) => {
    return {
      name: item.name,
      reason: item.reason.reduce((acc, i) => {
        if (!acc[i]) {
          acc[i] = 1;
        } else {
          acc[i] += 1;
        }
        return acc;
      }, {}),
    };
  });
}

export default {
  async create(file) {
    const bodyForm = new FormData();
    bodyForm.append('files', file);
    const data = await ConverterAxios.post('/', bodyForm);
    // const data = {
    //   created: [
    //     {
    //       id: 85,
    //       name: {
    //         es: 'ABN_EVAC_Bend:Hembra-Hembra',
    //         en: 'ABN_EVAC_Bend:Hembra-Hembra',
    //       },
    //       itemCategory: { id: 4, localizedText: { es: 'None', en: 'None' } },
    //       refCode: 'NoRefCodeFound',
    //       refType: 'NoRefTypeFound',
    //       gpbWaterLiters: 0,
    //       gpbCo2: 0,
    //       gpbEnergy: 0,
    //       elementType: 'CONNECTION',
    //       minLength: 0,
    //       maxLength: 0,
    //       model: { id: 100, name: 'ABN_EVAC_Bend:Hembra-Hembra' },
    //       brand: { id: 4, name: { es: 'None', en: 'None' } },
    //       reason: null,
    //     },
    //   ],
    //   failed: [
    //     {
    //       id: 0,
    //       name: {
    //         es: 'Brace Polypropylene / Abrazadera Polipropileno',
    //         en: 'Brace Polypropylene / Abrazadera Polipropileno',
    //       },
    //       itemCategory: {
    //         id: 0,
    //         localizedText: {
    //           es: 'Brace / Abrazadera',
    //           en: 'Brace / Abrazadera',
    //         },
    //       },
    //       refCode: 'DR6AC04000000',
    //       refType: 'DN 40',
    //       gpbWaterLiters: 0.0006477,
    //       gpbCo2: 0.0901,
    //       gpbEnergy: 11815,
    //       elementType: null,
    //       minLength: 0,
    //       maxLength: 0,
    //       model: null,
    //       brand: { id: 0, name: { es: 'CT FASER GRIS', en: 'CT FASER GRIS' } },
    //       reason: 'NO_CONNECTORS;   NO_REF_CODE',
    //     },
    //   ],
    // };

    return {
      created: itemTransformations(data.created),
      failed: parseFails(data.failed),
    };
  },
  async update({ id, ...values }) {
    return StrapiAxios.put(`/items/${id}`, values);
  },
  async upload(files) {
    const file = files[0];
    const bodyForm = new FormData();
    bodyForm.append('files', file);
    const uploaded = await StrapiAxios.post('/upload', bodyForm);
    const media = uploaded[0];
    return {
      id: media.id,
      name: media.name,
      url: `${process.env.REACT_APP_SERVER_URL}${media.url}`,
    };
  },
  async prices(file) {
    const bodyForm = new FormData();
    bodyForm.append('files.file', file);
    bodyForm.append('data', '{}');
    return StrapiAxios.post('/items/prices', bodyForm);
  },
};
