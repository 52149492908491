export default (allParams) => {
  if (!allParams) {
    return '';
  }
  let result = '?';
  const { where, ...params } = allParams;
  if (where) {
    Object.keys(where).forEach((w, i) => {
      if (result.length !== 1) {
        result += '&';
      }
      result += `_where[_or][${i}][${w}]=${where[w]}`;
    });
  }

  if (params) {
    Object.keys(params).forEach((p) => {
      if (result.length !== 1) {
        result += '&';
      }
      if (params[p]) {
        result += `${p}=${params[p]}`;
      }
    });
  }
  return result;
};
