function getImageUrl(image, size) {
  const INITIAL_URL = process.env.REACT_APP_SERVER_URL;
  if (image && image.formats && image.formats[size]) {
    return `${INITIAL_URL}${image.formats[size].url}`;
  }

  if (image && image.url) {
    return `${INITIAL_URL}${image.url}`;
  }
  return null;
}

export { getImageUrl };
