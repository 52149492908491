import React from 'react';
import { Provider } from 'react-redux';
import { Grommet } from 'grommet';
import { getPersistor } from '@rematch/persist';
import { ThemeProvider } from 'react-jss';
import { PersistGate } from 'redux-persist/es/integration/react';
import { I18nextProvider } from 'react-i18next';
import SnackbarProvider from 'react-simple-snackbar';
import i18next from './i18n';
import store from './state/store';
import Routes from './pages/Routes';
import theme from './theme';
import CookieBanner from './components/CookieBanner';

const App = () => {
  const persistor = getPersistor();
  return (
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <I18nextProvider i18n={i18next}>
          <ThemeProvider theme={theme}>
            <Grommet theme={theme}>
              <SnackbarProvider>
                <Routes />
                <CookieBanner />
              </SnackbarProvider>
            </Grommet>
          </ThemeProvider>
        </I18nextProvider>
      </Provider>
    </PersistGate>
  );
};

export default App;
