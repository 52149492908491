import catalogServices from '../../services/catalog-services';

function getEditableFieldsValues(item) {
  const image = item.image ? JSON.parse(item.image) : {};
  return {
    id: item.id,
    name: item.name,
    elementType: item.elementType,
    minLength: item.minLength,
    maxLength: item.maxLength,
    verified: new Date().toISOString(),
    image: image.id,
    price: item.price === '' ? null : item.price,
  };
}

export default (dispatch) => ({
  async create(files) {
    try {
      const promises = files.map((file) => catalogServices.create(file));
      const results = await Promise.all(promises);
      let createdItems = [];
      let failedItems = [];
      results.forEach(({ created, failed }) => {
        createdItems = [...createdItems, ...(created || [])];
        failedItems = [...failedItems, ...(failed || [])];
      });
      dispatch.catalog.setItemsToApprove([]);
      dispatch.catalog.setItemsFailed([]);
      dispatch.catalog.setItemsToApprove(createdItems);
      dispatch.catalog.setItemsFailed(failedItems);
      return null;
    } catch (errorMessage) {
      return errorMessage;
    }
  },
  async validate(items) {
    try {
      const itemsApprovedClean = [];
      items.forEach((item) => {
        if (item.shouldSave && item.shouldSave === 'true') {
          // The form builder return an string instead of a real boolean.
          itemsApprovedClean.push(getEditableFieldsValues(item));
        }
      });

      const updatePromises = itemsApprovedClean.map(catalogServices.update);
      await Promise.all(updatePromises);
      return null;
    } catch (err) {
      return err;
    }
  },
  async prices(files) {
    try {
      const promises = files.map((file) => catalogServices.prices(file));
      const results = await Promise.all(promises);
      const result = {
        failed: 0,
        success: 0,
        notFound: 0,
      };
      results.forEach((r) => {
        result.failed += r.summary.failed;
        result.success += r.summary.success;
        result.notFound += r.summary.reasons && r.summary.reasons.NOT_FOUND;
      });
      return result;
    } catch (err) {
      return err;
    }
  },
});
