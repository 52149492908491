export default {
  set(state, payload) {
    const { collection, data } = payload;
    return {
      ...state,
      collections: {
        ...state.collections,
        [collection]: data,
      },
    };
  },
  clean(state, collection) {
    const newCollections = { ...state.collections };
    delete newCollections[collection];
    return {
      ...state,
      collections: newCollections,
    };
  },
  cleanCurrent(state, collection) {
    const newCurrents = { ...state.current };
    delete newCurrents[collection];
    return {
      ...state,
      current: newCurrents,
    };
  },
  setCurrent(state, payload) {
    const { collection, data } = payload;
    return {
      ...state,
      current: {
        ...state.current,
        [collection]: data,
      },
    };
  },
};
