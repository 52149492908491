import ContentServices from '../../services/content-services';
import { getStrapiErrorMessage } from '../../utils/error-handler';
import paginationSelectors from './pagination-selectors';
import INITIAL_PAGINATION from '../../constants/initial-pagination';

function getPages(count) {
  const division = count / INITIAL_PAGINATION.limit;
  const pages = Math.floor(division);
  const decimal = pages - division;

  if (decimal === 0 && pages !== 0) {
    return pages - 1;
  }
  return pages;
}

export default (dispatch) => ({
  async init({ collection, params }) {
    try {
      dispatch.content.get({
        collection,
        params: {
          _limit: INITIAL_PAGINATION.limit,
          _start: 0,
          ...params,
        },
      });
      const count = await ContentServices.count(collection, params);
      dispatch.pagination.set({
        collection,
        data: {
          page: 0,
          limit: INITIAL_PAGINATION.limit,
          pages: getPages(count),
          count,
        },
      });
      return null;
    } catch (err) {
      return getStrapiErrorMessage(err);
    }
  },
  async onNext({ collection, callback }, state) {
    try {
      const currentPagination = paginationSelectors.get(collection)(state);
      if (currentPagination.page === currentPagination.pages) {
        return null;
      }
      const newPagination = {
        ...currentPagination,
        page: currentPagination.page + 1,
      };

      dispatch.pagination.set({
        collection,
        data: newPagination,
      });
      if (callback) {
        await callback(newPagination);
      }
      return null;
    } catch (err) {
      return getStrapiErrorMessage(err);
    }
  },
  async onPrevious({ collection, callback }, state) {
    try {
      const currentPagination = paginationSelectors.get(collection)(state);
      if (currentPagination.page === 0) {
        return null;
      }
      const newPagination = {
        ...currentPagination,
        page: currentPagination.page - 1,
      };

      dispatch.pagination.set({
        collection,
        data: newPagination,
      });
      if (callback) {
        await callback(newPagination);
      }
      return null;
    } catch (err) {
      return getStrapiErrorMessage(err);
    }
  },
});
