import { init } from '@rematch/core';
import createRematchPersist from '@rematch/persist';
import createLoadingPlugin from '@rematch/loading';
import auth from './auth/auth-model';
import content from './content/content-model';
import catalog from './catalog/catalog-model';
import pagination from './pagination/pagination-model';
import user from './user/user-model';
import bimProject from './bim-project/bim-project-model';
import stats from './stats/stats-model';

const persistPlugin = createRematchPersist({
  whitelist: ['auth'],
  throttle: 5000,
});
const loadingPlugin = createLoadingPlugin();

const models = {
  auth,
  bimProject,
  content,
  catalog,
  pagination,
  user,
  stats,
};

const store = init({
  models,
  plugins: [persistPlugin, loadingPlugin],
});

export const { dispatch } = store;
export default store;
