import axios from 'axios';
import errorHandler from '../utils/error-handler';
import { TOKEN_KEY } from '../constants/local-storage-keys';

const baseURL = process.env.REACT_APP_SERVER_URL;
const SAVED_TOKEN = window.localStorage.getItem(TOKEN_KEY);

const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

if (SAVED_TOKEN) {
  axiosInstance.defaults.headers.Authorization = SAVED_TOKEN;
}

export const setToken = (token) => {
  const bearerToken = `Bearer ${token}`;
  window.localStorage.setItem(TOKEN_KEY, bearerToken);
  axiosInstance.defaults.headers.Authorization = bearerToken;
};

export const removeToken = () => {
  window.localStorage.removeItem(TOKEN_KEY);
  delete axiosInstance.defaults.headers.Authorization;
};

export const getBearerToken = () => {
  return axiosInstance.defaults.headers.Authorization;
};

export const getToken = () => {
  const BearerToken = getBearerToken();
  return BearerToken.split(' ')[1];
};

function onError(err) {
  if (err.response.data.statusCode === 401) {
    removeToken();
    window.location.reload();
  }
  return errorHandler(err);
}

axiosInstance.interceptors.response.use(
  (res) => Promise.resolve(res.data),
  (err) => Promise.reject(onError(err))
);

export default axiosInstance;
