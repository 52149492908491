export default {
  set(state, payload) {
    const { collection, data } = payload;
    return {
      ...state,
      section: {
        ...state.section,
        [collection]: data,
      },
    };
  },
  clean(state, collection) {
    const newSection = { ...state.section };
    delete newSection[collection];
    return {
      ...state,
      section: newSection,
    };
  },
};
