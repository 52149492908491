export const getStrapiErrorMessage = (error = {}) => {
  if (
    error.message &&
    Array.isArray(error.message) &&
    error.message[0].messages
  ) {
    const e = error.message[0].messages[0];
    const fieldArray = e.id && e.id.split('.');
    const field = fieldArray[fieldArray.length - 2];
    return {
      message: e.message,
      field,
    };
  }
  return { message: error.message };
};

export default (err) => {
  if (!err.response || !err.response.data) {
    return err;
  }
  return err.response.data;
};
