import StrapiAxios from './StrapiAxios';
import { getStrapiErrorMessage } from '../utils/error-handler';

function convertErrorMessage(err) {
  if (err.includes('password invalid')) {
    return 'Usuario o contraseña incorrectos';
  }
  return err;
}

export default {
  async login(email, password) {
    try {
      return await StrapiAxios.post('/auth/local', {
        identifier: email,
        password,
      });
    } catch (err) {
      const { message } = getStrapiErrorMessage(err);
      return Promise.reject(convertErrorMessage(message));
    }
  },
  async forgotPassword(email) {
    return StrapiAxios.post('/auth/forgot-password', {
      email,
    });
  },
  async resetPassword(code, password) {
    return StrapiAxios.post('/auth/reset-password', {
      code,
      password,
      passwordConfirmation: password,
    });
  },
  async grantProjectAccess(email, project, level) {
    return StrapiAxios.post('/project-permissions', {
      email,
      project,
      level,
    });
  },
  async updateProjectAccess(permissionId, level) {
    return StrapiAxios.put(`/project-permissions/${permissionId}`, { level });
  },
  async deleteProjectAccess(permissionId) {
    return StrapiAxios.delete(`/project-permissions/${permissionId}`);
  },
};
