import StrapiAxios, { getToken } from './StrapiAxios';
import BimAxios from './BimAxios';

export default {
  async upload(file) {
    const bodyForm = new FormData();
    bodyForm.append('files', file);
    const data = await StrapiAxios.post('/upload', bodyForm);
    return data[0];
  },
  async buildModel(modelId) {
    return BimAxios.post('/request', {
      server: process.env.REACT_APP_SERVER_URL,
      platform: 'WebGL-Android-iOS',
      projectName: process.env.REACT_APP_BIM_CONVERTER_PROJECT_NAME,
      jwt: getToken(),
      modelId,
    });
  },
};
