import axios from 'axios';
import errorHandler from '../utils/error-handler';

const baseURL = process.env.REACT_APP_BIM_CONVERTER_URL;

const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.response.use(
  (res) => Promise.resolve(res.data),
  (err) => Promise.reject(errorHandler(err))
);

export default axiosInstance;
