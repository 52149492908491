import routenames from './routenames';

function get(title) {
  return `ABN | ${title}`;
}

export default {
  [routenames.login]: get('Welcome'),
  [routenames.forgot]: get('Forgot password'),
  [routenames.reset]: get('Reset password'),
  [routenames.signup]: get('New user'),
  [routenames.userConfirmation]: get('User confirmation'),
  [routenames.home]: get('Projects'),
  [routenames.project]: get('Project details'),
  [routenames.room]: get('Room details'),
  [routenames.roomListByCategory]: get('Room list'),
  [routenames.bim]: get('BIM project'),
  [routenames.catalogList]: get('Catalog'),
  [routenames.newItem]: get('New item'),
  [routenames.catalog]: get('Item detail'),
  [routenames.userList]: get('User list'),
  [routenames.user]: get('User details'),
  [routenames.self]: get('Me'),
  [routenames.stats]: get('Statistics'),
};
