import React from 'react';
import propTypes from 'prop-types';
import withStyles from 'react-jss';
import { Header } from 'grommet';
import { TapeOption, MoreVertical, User } from 'grommet-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import routenames from '../constants/routenames';
import authSelectors from '../state/auth/auth-selectors';
import Menu from '../components/Menu';
import MenuItem from '../components/MenuItem';
import { onEnter } from '../utils/keyboard-utils';
import { LANGUAGE_KEY } from '../constants/local-storage-keys';
import languages from '../constants/languages';
import Logo from '../assets/logo.jpg';

const styles = (theme) => ({
  menu: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0px 15px',
    '&:hover': {
      background: theme.global.colors.hover,
    },
  },
  text: {
    color: theme.global.colors.text.light,
    padding: '10px 5px',
  },
  logo: {
    cursor: 'pointer',
    height: '38px',
    margin: '5px 8px',
  },
});

const AppHOC = ({ classes, children, i18n, t }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onLogout = () => {
    dispatch.auth.logout();
    history.push(routenames.login);
  };
  const onProfile = () => {
    history.push(routenames.self);
  };
  const onChangeLanguage = (lng) => {
    window.localStorage.setItem(LANGUAGE_KEY, lng);
    i18n.changeLanguage(lng);
  };

  const user = useSelector(authSelectors.getUserInfo);
  const onHomeClick = () => {
    history.push(routenames.home);
  };
  return (
    <>
      <Header background='brand'>
        <div
          role='button'
          onClick={onHomeClick}
          onKeyDown={onEnter(onHomeClick)}
          tabIndex={0}
        >
          <img className={classes.logo} src={Logo} alt='Logo de ABN' />
        </div>
        <Menu
          trigger={
            <div className={classes.menu}>
              {user.username} <MoreVertical size='medium' />
            </div>
          }
        >
          <div>
            <MenuItem
              id='spanish'
              icon={<span>{`${languages.es.flag}`}</span>}
              label={t('header.spanish')}
              onClick={() => onChangeLanguage('es')}
            />
            <MenuItem
              id='english'
              icon={<span>{`${languages.en.flag}`}</span>}
              label={t('header.english')}
              onClick={() => onChangeLanguage('en')}
            />
            <MenuItem
              id='me'
              icon={<User color='brand' size='medium' />}
              label='Profile'
              onClick={onProfile}
            />
            <MenuItem
              id='logout'
              icon={<TapeOption color='brand' size='medium' />}
              label='Logout'
              onClick={onLogout}
            />
          </div>
        </Menu>
      </Header>
      <div style={{ height: 'calc(100% - 48px)', overflow: 'auto' }}>
        {children}
      </div>
    </>
  );
};

AppHOC.propTypes = {
  classes: propTypes.object.isRequired,
  children: propTypes.object,
  t: propTypes.func.isRequired,
  i18n: propTypes.object.isRequired,
};

AppHOC.defaultProps = {
  children: {},
};

export default withTranslation()(withStyles(styles)(AppHOC));
