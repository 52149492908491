import reducers from './content-reducers';
import effects from './content-effects';

export default {
  state: {
    collections: {},
    current: {},
  },
  effects,
  reducers,
};
