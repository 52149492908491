import UserServices from '../../services/user-services';
import { getStrapiErrorMessage } from '../../utils/error-handler';

export default () => ({
  async create({ username, password, email }) {
    try {
      await UserServices.create({ username, password, email });
      return null;
    } catch (err) {
      return getStrapiErrorMessage(err);
    }
  },
});
