import get from 'get-value';
import authServices from '../../services/auth-services';
import { setToken, removeToken } from '../../services/StrapiAxios';

function getForgetPasswordErrorMessage(err) {
  const message = get(err, 'message.0.messages.0.message');
  if (!message) {
    return typeof err.message === 'string' ? err.message : 'Unknown Error';
  }
  // We don't want to clarify if this user exists in our database our not
  if (message.includes('not exist')) {
    return null;
  }
  if (message.includes('valid')) {
    return 'Email address not valid';
  }
  return message;
}

function getResetPasswordErrorMessage(err) {
  const message = get(err, 'message.0.messages.0.message');
  if (!message) {
    return typeof err.message === 'string' ? err.message : 'Unknown Error';
  }
  if (message.includes('Incorrect code')) {
    return 'Código incorrecto o usado. Por favor, vuelva a iniciar el proceso.';
  }

  return message;
}

export default (dispatch) => ({
  async login({ identifier, password }) {
    try {
      const { jwt, user } = await authServices.login(identifier, password);
      setToken(jwt);
      dispatch.auth.setUserInfo(user);
      return null;
    } catch (errorMessage) {
      return errorMessage;
    }
  },
  async logout() {
    removeToken(null);
  },
  async forgotPassword(email) {
    try {
      await authServices.forgotPassword(email);
      return null;
    } catch (err) {
      return getForgetPasswordErrorMessage(err);
    }
  },
  async resetPassword({ code, password }) {
    try {
      const { jwt, user } = await authServices.resetPassword(code, password);
      setToken(jwt);
      dispatch.auth.setUserInfo(user);
      return null;
    } catch (err) {
      return getResetPasswordErrorMessage(err);
    }
  },
  async grantProjectAccess({ project, email, level }) {
    try {
      await authServices.grantProjectAccess(email, project, level);
      return null;
    } catch (err) {
      return err;
    }
  },
  async updateProjectAccess({ permissionId, level }) {
    try {
      if (level === 'delete') {
        await authServices.deleteProjectAccess(permissionId);
      } else {
        await authServices.updateProjectAccess(permissionId, level);
      }
      return null;
    } catch (err) {
      return err;
    }
  },
});
