import effects from './pagination-effects';
import reducers from './pagination-reducers';

export default {
  state: {
    section: {},
  },
  effects,
  reducers,
};
