import contentServices from '../../services/content-services';
import StatsServices from '../../services/stats-services';
import { getStrapiErrorMessage } from '../../utils/error-handler';

function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i += 1) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const PIE_COLORS = [
  '#8FD5A6',
  '#D5B9B2',
  '#7286A0',
  '#D7B377',
  '#D17A22',
  '#B3A394',
  '#2A628F',
];

async function transformCategories(rawStats) {
  const rawCategories = await contentServices.get('project-categories');
  const categories = {};
  rawCategories.forEach((c) => {
    categories[c.id] = c.name;
  });

  const categoriesStats = [];
  rawStats.categories.forEach((c) => {
    if (categories[c.category]) {
      categoriesStats.push({
        id: c.category,
        value: c.amount,
        title: categories[c.category]
          ? categories[c.category].es
          : 'No category',
        color: PIE_COLORS[categoriesStats.length] || getRandomColor(),
      });
    }
  });
  return categoriesStats;
}

function transformProvinces(rawStats) {
  const provinces = {};
  rawStats.provinces.forEach((province) => {
    provinces[province.name] = province.amount;
  });
  return provinces;
}

async function transformRoomTypes(rawStats) {
  const rawRoomTypes = await contentServices.get('room-configuration-types');
  const roomTypes = {};
  rawRoomTypes.forEach((rt) => {
    roomTypes[rt.id] = rt.textLocalized;
  });

  return rawStats.rooms.map((room, i) => ({
    id: room.type,
    value: room.amount,
    title: roomTypes[room.type].es,
    color: PIE_COLORS[i],
  }));
}

function transformPages({ pages }) {
  const totalVisits = Object.values(pages).reduce((v, acc) => v + acc, 0);
  return Object.keys(pages).map((t) => ({
    title: t,
    amount: pages[t],
    percentage: (pages[t] / totalVisits) * 100,
  }));
}

export default (dispatch) => ({
  async get(params) {
    try {
      const rawStats = await StatsServices.get(params);
      const categories = await transformCategories(rawStats);
      const provinces = transformProvinces(rawStats);
      const roomTypes = await transformRoomTypes(rawStats);
      const pages = transformPages(rawStats);
      dispatch.stats.set({
        section: 'general',
        data: {
          categories,
          provinces,
          roomTypes,
          totals: rawStats.totals,
          items: rawStats.items,
          pages,
        },
      });
      return null;
    } catch (err) {
      return getStrapiErrorMessage(err);
    }
  },
});
