import { getImageUrl } from '../../../utils/image-utils';

export default function itemTransformations(items) {
  return items.map(({ id, ...item }) => {
    let newImage = {};
    if (item.image) {
      newImage = {
        name: item.image.name,
        url: getImageUrl(item.image, 'small'),
      };
    }
    return {
      ...item,
      shouldSave: true,
      itemId: id,
      brand: item.brand.name,
      gpbWaterLiters: `${item.gpbWaterLiters} L`,
      gpbCo2: `${item.gpbCo2} CO₂`,
      gpbEnergy: `${item.gpbEnergy} J`,
      itemCategory: item.itemCategory.localizedText,
      image: item.image ? JSON.stringify(newImage) : '',
    };
  });
}
