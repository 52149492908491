import bimProjectServices from '../../services/bim-project-services';
import contentServices from '../../services/content-services';

export default (dispatch) => ({
  async import({ files, room }) {
    try {
      const uploadPromises = files.map((file) =>
        bimProjectServices.upload(file)
      );
      const filesUploaded = await Promise.all(uploadPromises);
      const modelPromises = filesUploaded.map((f) =>
        contentServices.create('models', {
          name: f.name,
          mesh: f.id,
        })
      );
      const models = await Promise.all(modelPromises);
      const promises = [];
      models.forEach((m) => {
        promises.push(
          contentServices.create('bim-projects', {
            name: m.name,
            model: m.id,
            room,
            state: 'READY',
          })
        );
        promises.push(bimProjectServices.buildModel(m.id));
      });
      await Promise.all(promises);
      dispatch.content.get({ collection: 'bim-projects', params: { room } });
      return null;
    } catch (err) {
      return err.message;
    }
  },
});
