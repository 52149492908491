export default {
  setItemsToApprove(state, payload) {
    return {
      ...state,
      itemsToApprove: payload,
    };
  },
  setItemsFailed(state, payload) {
    return {
      ...state,
      itemsFailed: payload,
    };
  },
  setError(state, error) {
    return {
      ...state,
      error,
    };
  },
  clean() {
    return {
      itemsToApprove: [],
      itemsFailed: [],
      error: null,
    };
  },
};
