import React from 'react';
import withStyles from 'react-jss';
import propTypes from 'prop-types';
import { onEnter } from '../utils/keyboard-utils';

const styles = (theme) => ({
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0px 15px',
    '&:hover': {
      background: theme.global.colors.hover,
    },
  },
  text: {
    color: theme.global.colors.text.light,
    padding: '10px 5px',
  },
});

const MenuItem = ({ classes, id, icon, label, onClick }) => {
  return (
    <div
      id={id}
      className={classes.menuItem}
      onClick={onClick}
      onKeyPress={onEnter(onClick)}
      role='button'
      tabIndex={0}
    >
      {icon}
      <span className={classes.text}>{label}</span>
    </div>
  );
};

MenuItem.propTypes = {
  classes: propTypes.object.isRequired,
  id: propTypes.string,
  icon: propTypes.object,
  label: propTypes.string,
  onClick: propTypes.func,
};

MenuItem.defaultProps = {
  id: undefined,
  icon: undefined,
  label: undefined,
  onClick: () => {},
};

export default withStyles(styles)(MenuItem);
