export default {
  setUserInfo(state, payload) {
    return {
      ...state,
      user: payload,
    };
  },
  setUsername(state, username) {
    return {
      ...state,
      user: {
        ...state.user,
        username,
      },
    };
  },
  setError(state, error) {
    return {
      ...state,
      error,
    };
  },
  setPermissionInCurrentProject(state, permission) {
    return {
      ...state,
      permissionsInCurrentProject: permission,
    };
  },
};
