import StrapiAxios from './StrapiAxios';
import transformQueryParams from '../utils/query-params';

export default {
  async get(collection, params) {
    return StrapiAxios.get(`/${collection}${transformQueryParams(params)}`);
  },
  async getOne(collection, id) {
    return StrapiAxios.get(`/${collection}/${id}`);
  },
  async create(collection, data) {
    return StrapiAxios.post(`/${collection}`, data);
  },
  async update(collection, id, data) {
    return StrapiAxios.put(`/${collection}/${id}`, data);
  },
  async delete(collection, id) {
    return StrapiAxios.delete(`/${collection}/${id}`);
  },
  async count(collection, params) {
    return StrapiAxios.get(
      `/${collection}/count${transformQueryParams(params)}`
    );
  },
};
