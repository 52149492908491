export default {
  global: {
    colors: {
      brand: '#4B5F6D',
      accent: 'rgb(221, 220, 89)',
      hover: '#aeb0d4',
      text: { dark: '#f8f8f8', light: '#788C9B' },
      disabled: '#A6BACA',
      statusCritical: '#D9594C',
      statusOk: '#00C781',
      background: '#ffffff',
      alterBackground: '#eff2f9',
      divider: '#DBE2EA',
    },
    font: {
      family: 'Poppins',
      size: '14px',
      height: '20px',
    },
    input: {
      font: {
        weight: '400',
      },
    },
  },
  text: {
    medium: {
      size: '14px',
    },
  },
};
