export const onEnter = (callback) => {
  return (e) => {
    if (e.key === 'Enter') {
      callback(e);
    }
  };
};

export const onEsc = (callback) => {
  return (e) => {
    if (e.key === 'Escape') {
      callback(e);
    }
  };
};
