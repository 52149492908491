import React, { useState } from 'react';
import propTypes from 'prop-types';
import withStyles from 'react-jss';
import { withTranslation } from 'react-i18next';
import { Button } from 'grommet';
import { COOKIE_CONSENT_KEY } from '../constants/local-storage-keys';

const styles = (theme) => ({
  container: {
    position: 'absolute',

    backgroundColor: theme.global.colors.accent,
    bottom: 0,
    width: '100%',
  },
  innerContainer: {
    margin: '18px 32px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const COOKIE_CONSENT = window.localStorage.getItem(COOKIE_CONSENT_KEY);

const CookieBanner = ({ classes, t }) => {
  const [aceptedNow, setAceptedNow] = useState(false);

  if (COOKIE_CONSENT || aceptedNow) {
    return null;
  }
  return (
    <div className={classes.container} data-testid='CookieBanner'>
      <div className={classes.innerContainer}>
        <span className={classes.text}>
          {t('cookieBanner.start')}
          <a href='https://ec.europa.eu/info/cookies_en'>cookies</a>
          {t('cookieBanner.end')}
        </span>
        <Button
          label={t('cookieBanner.label')}
          onClick={() => {
            setAceptedNow(true);
            window.localStorage.setItem(COOKIE_CONSENT_KEY, true);
          }}
        />
      </div>
    </div>
  );
};

CookieBanner.propTypes = {
  classes: propTypes.object.isRequired,
  t: propTypes.func.isRequired,
};

export default withTranslation()(withStyles(styles)(CookieBanner));
