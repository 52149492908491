export default {
  login: '/login',
  forgot: '/forgot',
  reset: '/reset',
  signup: '/signup',
  userConfirmation: '/confirmation',
  home: '/',
  project: '/project/:id',
  room: '/project/:projectId/:id',
  roomListByCategory: '/room/category/:id',
  bim: '/project/:projectId/:roomId/:id',
  catalogList: '/catalog',
  newItem: '/catalog/new',
  catalog: '/catalog/:id',
  userList: '/user',
  user: '/user/:id',
  self: '/user/me',
  stats: '/stats',
};
