import React, { Suspense } from 'react';
import propTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import withStyles from 'react-jss';
import { ScaleLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import routenames from '../constants/routenames';
import { getBearerToken } from '../services/StrapiAxios';
import AppHOC from './AppHOC';
import { PROJECT, USER, CATALOG, STATS } from '../constants/left-menu-items';
import ROLES from '../constants/roles-constants';
import authSelectors from '../state/auth/auth-selectors';
import TitleController from '../components/TitleController';

const Login = React.lazy(() => import('./Login'));
const ForgotPassword = React.lazy(() => import('./ForgotPassword'));
const ResetPassword = React.lazy(() => import('./ResetPassword'));
const CatalogList = React.lazy(() => import('./CatalogList'));
const CatalogNewItem = React.lazy(() => import('./CatalogNewItem'));
const CatalogDetails = React.lazy(() => import('./CatalogDetails'));
const ProjectList = React.lazy(() => import('./ProjectList'));
const ProjectDetails = React.lazy(() => import('./ProjectDetails'));
const RoomDetails = React.lazy(() => import('./RoomDetails'));
const RoomListByCategory = React.lazy(() => import('./RoomListByCategory'));
const BimProjectDetails = React.lazy(() => import('./BimProjectDetails'));
const LeftMenu = React.lazy(() => import('./LeftMenu'));
const SignUp = React.lazy(() => import('./SignUp'));
const UserConfirmation = React.lazy(() => import('./UserConfirmation'));
const UserList = React.lazy(() => import('./UserList'));
const UserDetails = React.lazy(() => import('./UserDetails'));
const Stats = React.lazy(() => import('./Stats'));

const styles = {
  container: {
    height: '100vh',
    overflow: 'hidden',
  },
};

const PrivateRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        getBearerToken() ? (
          <AppHOC>{children}</AppHOC>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const AdminRoute = ({ children, ...rest }) => {
  const role = useSelector(authSelectors.getUserRole);
  return (
    <PrivateRoute {...rest}>
      {role === ROLES.ADMIN ? children : <Redirect to={{ pathname: '/' }} />}
    </PrivateRoute>
  );
};

const Routes = ({ classes }) => {
  return (
    <div className={classes.container}>
      <Router>
        <Suspense fallback={<ScaleLoader />}>
          <TitleController />
          <Switch>
            <Route path={routenames.login}>
              <Login />
            </Route>
            <Route path={routenames.forgot}>
              <ForgotPassword />
            </Route>
            <Route path={routenames.reset}>
              <ResetPassword />
            </Route>
            <Route path={routenames.signup}>
              <SignUp />
            </Route>
            <Route path={routenames.userConfirmation}>
              <UserConfirmation />
            </Route>
            <PrivateRoute exact path={routenames.bim}>
              <LeftMenu selected={PROJECT}>
                <BimProjectDetails />
              </LeftMenu>
            </PrivateRoute>
            <PrivateRoute exact path={routenames.room}>
              <LeftMenu selected={PROJECT}>
                <RoomDetails />
              </LeftMenu>
            </PrivateRoute>
            <AdminRoute exact path={routenames.roomListByCategory}>
              <LeftMenu selected={PROJECT}>
                <RoomListByCategory />
              </LeftMenu>
            </AdminRoute>
            <PrivateRoute path={routenames.project}>
              <LeftMenu selected={PROJECT}>
                <ProjectDetails />
              </LeftMenu>
            </PrivateRoute>
            <PrivateRoute exact path={routenames.home}>
              <LeftMenu selected={PROJECT}>
                <ProjectList />
              </LeftMenu>
            </PrivateRoute>
            <PrivateRoute exact path={routenames.catalogList}>
              <LeftMenu selected={CATALOG}>
                <CatalogList />
              </LeftMenu>
            </PrivateRoute>
            <AdminRoute exact path={routenames.newItem}>
              <LeftMenu selected={CATALOG}>
                <CatalogNewItem />
              </LeftMenu>
            </AdminRoute>
            <PrivateRoute exact path={routenames.catalog}>
              <LeftMenu selected={CATALOG}>
                <CatalogDetails />
              </LeftMenu>
            </PrivateRoute>
            <AdminRoute exact path={routenames.userList}>
              <LeftMenu selected={USER}>
                <UserList />
              </LeftMenu>
            </AdminRoute>
            <PrivateRoute exact path={routenames.self}>
              <LeftMenu selected={USER}>
                <UserDetails />
              </LeftMenu>
            </PrivateRoute>
            <AdminRoute path={routenames.user}>
              <LeftMenu selected={USER}>
                <UserDetails />
              </LeftMenu>
            </AdminRoute>
            <AdminRoute exact path={routenames.stats}>
              <LeftMenu selected={STATS}>
                <Stats />
              </LeftMenu>
            </AdminRoute>
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
};

Routes.propTypes = {
  classes: propTypes.object.isRequired,
};

AdminRoute.propTypes = {
  children: propTypes.object.isRequired,
};

PrivateRoute.propTypes = {
  children: propTypes.object.isRequired,
};

export default withStyles(styles)(Routes);
