import React from 'react';
import Popup from 'reactjs-popup';
import propTypes from 'prop-types';

const Menu = ({ children, trigger, hover, position }) => {
  return (
    <Popup
      trigger={trigger}
      position={position}
      on={hover ? 'hover' : 'click'}
      closeOnDocumentClick
      mouseLeaveDelay={300}
      mouseEnterDelay={0}
      contentStyle={{ padding: '15px 0px', border: 'none' }}
      arrow
    >
      {children}
    </Popup>
  );
};

Menu.propTypes = {
  children: propTypes.oneOfType([propTypes.object, propTypes.array]).isRequired,
  trigger: propTypes.object.isRequired,
  hover: propTypes.bool,
  position: propTypes.string,
};

Menu.defaultProps = {
  hover: false,
  position: 'bottom right',
};

export default Menu;
